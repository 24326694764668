define("adept-iq/classes/map-contexts/map-configs/schedule-layers", ["exports", "adept-iq/config/icon-paths", "adept-iq/pods/components/iq-widgets/routes-widget/scheduleConfig", "adept-iq/pods/components/iq-widgets/trips-widget/scheduleConfig", "adept-iq/pods/components/iq-widgets/stops-widget/scheduleConfig"], function (_exports, _iconPaths, _scheduleConfig, _scheduleConfig2, _scheduleConfig3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @TODO: make this JSON-only
  // @TODO: it would be better to look up values from static config at time of use,
  // but for now this handles the differences in config format
  const extractLabelsFromWidgetConfig = widgetConfig => {
    return widgetConfig.columns.map(columnConfig => {
      const {
        id,
        index,
        type,
        label,
        valuePath,
        mapValuePath,
        valuePreview,
        searchable,
        isMapLabelVisible,
        unAvailable,
        format
      } = columnConfig;
      return {
        id,
        index,
        type,
        label,
        valuePath,
        mapValuePath,
        valuePreview,
        format,
        isSearchable: searchable,
        isVisible: isMapLabelVisible,
        unAvailable: unAvailable
      };
    });
  };

  const layers = [{
    id: 'stops',
    type: 'markers',
    label: 'Stops',
    //modelName: ['trip-stop', 'route-break', 'stop-point'], // needs to aggregate different types of models
    modelName: 'trip-stop',
    // needs to aggregate different types of models
    isVisible: true,
    isActive: true,
    opacity: 1,
    types: [{
      id: 'pick1',
      label: 'Pickup',
      filterKey: 'mapId',
      filterValue: 'pick1',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlanned1
    }, {
      id: 'drop1',
      label: 'Drop Off',
      filterKey: 'mapId',
      filterValue: 'drop1',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.dropPlanned1
    }, {
      id: 'pullinGarage1',
      label: 'Pullin Garage',
      filterKey: 'mapId',
      filterValue: 'pullinGarage1',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned1
    }, {
      id: 'pulloutGarage1',
      label: 'Pullout Garage',
      filterKey: 'mapId',
      filterValue: 'pulloutGarage1',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned1
    }, {
      id: 'pick2',
      label: 'Pickup',
      filterKey: 'mapId',
      filterValue: 'pick2',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlanned2
    }, {
      id: 'drop2',
      label: 'Drop Off',
      filterKey: 'mapId',
      filterValue: 'drop2',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.dropPlanned2
    }, {
      id: 'pullinGarage2',
      label: 'Pullin Garage2',
      filterKey: 'mapId',
      filterValue: 'pullinGarage',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned2
    }, {
      id: 'pulloutGarage2',
      label: 'Pullout Garage',
      filterKey: 'mapId',
      filterValue: 'pulloutGarage2',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned2
    }, {
      id: 'pick3',
      label: 'Pickup',
      filterKey: 'mapId',
      filterValue: 'pick3',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlanned3
    }, {
      id: 'drop3',
      label: 'Drop Off',
      filterKey: 'mapId',
      filterValue: 'drop3',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.dropPlanned3
    }, {
      id: 'pullinGarage3',
      label: 'Pullin Garage',
      filterKey: 'mapId',
      filterValue: 'pullinGarage3',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned3
    }, {
      id: 'pulloutGarage3',
      label: 'Pullout Garage',
      filterKey: 'mapId',
      filterValue: 'pulloutGarage3',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned3
    }, {
      id: 'pick4',
      label: 'Pickup',
      filterKey: 'mapId',
      filterValue: 'pick4',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlanned4
    }, {
      id: 'drop4',
      label: 'Drop Off',
      filterKey: 'mapId',
      filterValue: 'drop4',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.dropPlanned4
    }, {
      id: 'pullinGarage4',
      label: 'Pullin Garage',
      filterKey: 'mapId',
      filterValue: 'pullinGarage4',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned4
    }, {
      id: 'pulloutGarage4',
      label: 'Pullout Garage',
      filterKey: 'mapId',
      filterValue: 'pulloutGarage4',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned4
    }, {
      id: 'pick5',
      label: 'Pickup',
      filterKey: 'mapId',
      filterValue: 'pick5',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlanned5
    }, {
      id: 'drop5',
      label: 'Drop Off',
      filterKey: 'mapId',
      filterValue: 'drop5',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.dropPlanned5
    }, {
      id: 'pullinGarage5',
      label: 'Pullin Garage',
      filterKey: 'mapId',
      filterValue: 'pullinGarage5',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned5
    }, {
      id: 'pulloutGarage5',
      label: 'Pullout Garage5',
      filterKey: 'mapId',
      filterValue: 'pulloutGarage5',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned5
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_scheduleConfig3.default)
  }, {
    id: 'routes',
    type: 'polylines',
    label: 'Routes',
    modelName: 'route',
    isVisible: true,
    isActive: true,
    opacity: 1,
    types: [{
      id: 'scheduled',
      label: 'Scheduled Route',
      isVisible: true,
      canFilter: true,
      display: true,
      iconPath: _iconPaths.default.routes.scheduled
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_scheduleConfig.default)
  }, {
    id: 'trips',
    type: 'polylines',
    label: 'Trips',
    modelName: 'trip',
    isVisible: true,
    isActive: true,
    opacity: 1,
    types: [{
      id: 'Completed',
      label: 'Completed',
      filterKey: 'tripStatus',
      filterValue: 'Completed',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.completed
    }, {
      id: 'Scheduled',
      label: 'Scheduled',
      filterKey: 'tripStatus',
      filterValue: 'Scheduled',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.scheduled
    }, {
      id: 'Waitlisted',
      label: 'Waitlisted',
      filterKey: 'tripStatus',
      filterValue: 'Waitlisted',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.waitListed
    }, {
      id: 'Callback',
      label: 'Callback',
      filterKey: 'tripStatus',
      filterValue: 'Callback',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.callback
    }, {
      id: 'Dispatched',
      label: 'Dispatched',
      filterKey: 'tripStatus',
      filterValue: 'Dispatched',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.dispatched
    }, {
      id: 'EnRoute',
      label: 'En Route',
      filterKey: 'tripStatus',
      filterValue: 'En route',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.enRoute
    }, {
      id: 'Active',
      label: 'Active',
      filterKey: 'tripStatus',
      filterValue: 'Active',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.active
    }, {
      id: 'NoShowed',
      label: 'No Showed',
      filterKey: 'tripStatus',
      filterValue: 'No showed',
      style: 'dotted',
      isVisible: false,
      display: false,
      canFilter: false,
      iconPath: _iconPaths.default.trips.noShowed
    }, {
      id: 'NoShowRequested',
      label: 'No Show Requested',
      filterKey: 'tripStatus',
      filterValue: 'No show requested',
      style: 'dotted',
      isVisible: false,
      display: false,
      canFilter: false,
      iconPath: _iconPaths.default.trips.noShowRequest
    }, {
      id: 'Illegal',
      label: 'Illegal',
      filterKey: 'tripStatus',
      filterValue: 'Illegal',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.illegal
    }, {
      id: 'UnScheduled',
      label: 'UnScheduled',
      filterKey: 'tripStatus',
      filterValue: 'UnScheduled',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.unScheduled
    }, {
      id: 'Canceled',
      label: 'Canceled',
      filterKey: 'tripStatus',
      filterValue: 'Canceled',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.canceled
    }, {
      id: 'Latecanceled',
      label: 'Late Canceled',
      filterKey: 'tripStatus',
      filterValue: 'Late canceled',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.lateCanceled
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_scheduleConfig2.default)
  }];
  var _default = layers;
  _exports.default = _default;
});