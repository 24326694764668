define("adept-iq/pods/components/iq-widgets/fleet-manager-widget/vehicle-table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/iq-widgets/fleet-manager-widget/vehicle-table/config", "ember-concurrency"], function (_exports, _component, _config, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['vehicle-table'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    config: _config.default,
    isModelLoading: true,

    refreshTable() {
      this.refreshData();
    },

    disableRowSelection: Ember.computed('editAPI.isEditing', function () {
      return !!this.get('editAPI.isEditing');
    }),

    async init() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      this.refreshTable = this.refreshTable.bind(this);
      this.resetCurrentSchedule = this.resetCurrentSchedule.bind(this);
      this.deleteVehicleRecord = this.deleteVehicleRecord.bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          deleteAction: this.deleteVehicleRecord
        });
      });
      const store = this.get('store');
      store.unloadAll('vehicle');
      store.unloadAll('route-template');
      store.unloadAll('route-template-availability');
      Promise.all([store.findAll('vehicle'), store.findAll('route-template'), store.findAll('route-template-availability')]).catch(error => {
        // eslint-disable-next-line no-console
        console.error('Fleet Management failed to load models :', error);
      }).finally(() => {
        this.set('isModelLoading', false);
        this.refreshTable();
      });
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
        this.onTableRefUpdated(this);
      }
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        return records.filter(record => !record.get('isNew'));
      });
    },

    async resetCurrentSchedule() {
      const workspace = this.workspace;
      const currentSchedule = workspace.get('currentSchedule');

      if (currentSchedule) {
        currentSchedule.set('status', 'unscheduled');
        await currentSchedule.save();
      }

      workspace.refreshWidgets();
    },

    deleteVehicleRecord() {
      this.get('deleteRecord').perform();
    },

    deleteRecord: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const notifications = this.get('notifications');
      const resetCurrentSchedule = this.get('resetCurrentSchedule');
      const checkedRow = tableRef.get('checkedRows.firstObject');

      if (Ember.isEmpty(checkedRow)) {
        console.warn('Workflow shouldn\'t reach this line'); // eslint-disable-line
      }

      const tooltip = this.get('tooltip');
      if (!checkedRow) return;
      return yield tooltip.pushConfirmation({
        title: 'Manage Vehicle',
        tip: `Are you sure you wish to delete vehicle record ${checkedRow.name}?`,
        hasOverlay: true,
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          try {
            // Find all routeTemplates and routeTemplateAvailabilities for the vehicle
            const routeTemplates = this.store.peekAll('route-template').filter(routeTemplate => routeTemplate.get('name') === checkedRow.get('name'));
            const routeTemplateAvailabilities = this.store.peekAll('route-template-availability').filter(availability => availability.get('vehicleId') === checkedRow.get('id'));
            const routeTemplateDestroyPromises = routeTemplates.length ? routeTemplates.map(routeTemplate => routeTemplate.destroyRecord()) : []; // Destroy all vehicle, routeTemplates in parallel . Delete of routeTemplates deletes routeTemplateAvailabilities

            await Promise.all([...routeTemplateDestroyPromises, checkedRow.destroyRecord()]);

            if (routeTemplateAvailabilities.length) {
              routeTemplateAvailabilities.forEach(availability => this.store.unloadRecord(availability));
            } // Refresh workspace and vehicle table


            await Promise.all([resetCurrentSchedule(), tableRef.refreshData()]);
            return tooltip.reset();
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error Vehicle Delete:', error);
            notifications.warning(`Error in deleting vehicle record  ${checkedRow.get('name')}`);
          }
        }
      });
    }),

    syncDataAfterUpload() {
      this.store.unloadAll('vehicle');
      this.store.unloadAll('route-template');
      this.store.unloadAll('route-template-availability');
      return Promise.all([this.store.findAll('vehicle', {
        reload: true
      }), this.store.findAll('route-template', {
        reload: true
      }), this.store.findAll('route-template-availability', {
        reload: true
      })]);
    },

    actions: {
      onEditClick() {
        const record = this.get('tableRef.checkedRows.firstObject'); //this.set('disableRowSelection', true);

        this.get('editAPI').editAction(record);
        this.get('editAPI').setProperties({
          isNewBtnDisabled: true,
          isEditBtnDisabled: true,
          isDeleteBtnDisabled: true,
          isSaveBtnDisabled: true,
          isUndoBtnDisabled: false,
          isUploadCSVBtnDisabled: true,
          isEditing: true,
          isNewRecord: false
        });
      },

      onDeleteClick() {
        this.get('editAPI').deleteAction();
      },

      onNewClick() {
        this.get('editAPI').newAction();
        this.get('editAPI').setProperties({
          isNewBtnDisabled: true,
          isEditBtnDisabled: true,
          isDeleteBtnDisabled: true,
          isSaveBtnDisabled: true,
          isUndoBtnDisabled: false,
          isUploadCSVBtnDisabled: true,
          isEditing: true,
          isNewRecord: true
        });
      },

      async onUploadCSVSuccess() {
        const tableRef = this.get('tableRef');
        await this.syncDataAfterUpload();
        await Promise.all([this.resetCurrentSchedule(), tableRef.refreshData()]);
        this.get('editAPI').setProperties({
          isNewBtnDisabled: false,
          isEditBtnDisabled: false,
          isDeleteBtnDisabled: false,
          isSaveBtnDisabled: true,
          isUndoBtnDisabled: true,
          isUploadCSVBtnDisabled: false,
          isEditing: false,
          isNewRecord: false
        });
      },

      onUndoClick() {
        this.get('editAPI').undoAction(); //this.set('disableRowSelection', false);

        this.get('editAPI').setProperties({
          isNewBtnDisabled: false,
          isEditBtnDisabled: false,
          isDeleteBtnDisabled: false,
          isSaveBtnDisabled: true,
          isUndoBtnDisabled: true,
          isUploadCSVBtnDisabled: false,
          isEditing: false,
          isNewRecord: false
        });
      }

    }
  });

  _exports.default = _default;
});