define("adept-iq/pods/components/schedule-metrics/component", ["exports", "lodash", "adept-iq/pods/components/schedule-metrics/config"], function (_exports, _lodash, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['schedule-metrics'],
    store: Ember.inject.service(),
    config: _config.default,
    isLoading: true,
    workspace: Ember.inject.service(),
    columnsData: null,

    init() {
      this._super(...arguments);

      this.set('columnsData', {});
      this.loadMetrics();
    },

    async loadMetrics() {
      try {
        const workspace = this.get('workspace');
        const currentSchedule = workspace.get('currentSchedule');
        let metric = null;

        if (currentSchedule.get('status') === 'scheduled') {
          metric = await currentSchedule.get('metric').reload();
        }

        this.populateColumnData(metric);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching scheduling metrics:', error);
      } finally {
        this.set('isLoading', false);
      }
    },

    populateColumnData(metric) {
      const config = this.get('config');

      const columns = _lodash.default.sortBy(config.columns, 'index');

      const columnsData = columns.map(column => {
        const value = metric ? metric.get(column.modelAttribute) : null;
        return { ...column,
          value: value !== null ? value : 'N/A'
        };
      });
      this.set('columnsData', columnsData);
    },

    actions: {
      onXButtonClick() {
        this.get('workspace').popState('openScheduleMetrics');
      }

    }
  });

  _exports.default = _default;
});