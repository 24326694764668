define("adept-iq/pods/login/route", ["exports", "lodash", "ember-simple-auth/mixins/unauthenticated-route-mixin", "moment", "md5"], function (_exports, _lodash, _unauthenticatedRouteMixin, _moment, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    ajax: Ember.inject.service(),
    map: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    setupController(controller
    /*, model*/
    ) {
      controller.set('data', {});
    },

    doLogUserSignOn() {
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      const eventLog = {
        type: 'event',
        attributes: {
          source: 'webApp',
          eventType: 'UE',
          actionType: 'signOn',
          userId: this.get('session.data.authenticated.userId'),
          receivedAt: (0, _moment.default)().toDate(),
          eventLog: {
            signOn: [{
              activity: 'Sign On',
              loggingType: 'user',
              details: 'Signed on'
            }]
          }
        }
      };
      return activityLogAdapter.createEventActivity(eventLog);
    },

    actions: {
      login(userName, password) {
        const session = this.get('session');
        const authenticator = 'authenticator:sso';
        let userLoggedIn = false;
        this.get('flashMessages').clearMessages();
        session.authenticate(authenticator, userName, password).then(() => {
          //this.get('map'); // initialize map for boundaryBox
          this.transitionTo().abort();
          this.get('workspace').initUserRecord().then(res => {
            if (res && !res.data) {
              localStorage.setItem('currentUser', userName);
              localStorage.setItem('currentPassword', (0, _md.default)(password));
              localStorage.setItem('notAllowedToProceed', true);
              localStorage.setItem('notAllowedToProceedTarget', 'require-new-password');
              this.replaceWith('require-new-password');
              return;
            }

            this.store.findAll('cs-config-permission').then(configPermissions => {
              this.set('workspace.configPermissions', configPermissions.get('firstObject.value'));
              localStorage.setItem('currentUser', userName);
              const userDash = localStorage.getItem('userDash');

              if (!userDash) {
                localStorage.setItem('userDash', `${userName}*null`);
                localStorage.removeItem('lastDashboardId');
              } else {
                const arr = userDash.split(',');

                const user = _lodash.default.find(arr, users => {
                  return users.split('*')[0] === userName;
                });

                if (!user) {
                  arr.push(`${userName}*null`);
                  localStorage.setItem('userDash', arr);
                  localStorage.removeItem('lastDashboardId');
                } else {
                  localStorage.setItem('lastDashboardId', user.split('*')[1]);
                }
              }

              userLoggedIn = true;
              return this.doLogUserSignOn();
            }).then(() => {
              this.transitionTo('index');
            }).catch(e => {
              if (userLoggedIn) {
                this.transitionTo('index');
              }

              let message;
              let errorMessage = e.message;

              if (errorMessage.includes('Unexpected response from ADEPT4 adapter:')) {
                errorMessage = 'InternalServerError';
              }

              switch (errorMessage) {
                case 'Unauthorized':
                  message = 'Email or password is not correct.';
                  break;

                case 'Disabled':
                  message = 'Your account is disabled. Please contact your system administrator.';
                  break;

                case 'InternalServerError':
                  message = 'An error occurred while connecting to ADEPT 4, please try again. If the problem persists, contact an administrator for assistance.';
                  break;

                default:
                  message = '';
              }

              if (Ember.isPresent(message)) {
                return this.set('controller.data.errors', [message]);
              }

              throw e;
            });
          });
        }).catch(e => {
          let message;
          let errorMessage = e.message;

          if (errorMessage.includes('Unexpected response from ADEPT4 adapter:')) {
            errorMessage = 'InternalServerError';
          }

          switch (errorMessage) {
            case 'Unauthorized':
              message = 'Email or password is not correct.';
              break;

            case 'Disabled':
              message = 'Your account is disabled. Please contact your system administrator.';
              break;

            case 'InternalServerError':
              message = 'An error occurred while connecting to ADEPT 4, please try again. If the problem persists, contact an administrator for assistance.';
              break;

            case 'MissingWorkspacePermission':
              message = 'Unable to sign on due to missing workspace permission to assigned role. Contact administrator';
              break;

            default:
              message = errorMessage;
          }

          if (Ember.isPresent(message)) {
            return this.set('controller.data.errors', [message]);
          }

          this.transitionTo().abort();
          throw e;
        });
      }

    }
  });

  _exports.default = _default;
});