define("adept-iq/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ob3brUgx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"application-wrapper\\n  \",[27,\"if\",[[23,[\"isTouchDevice\"]],\"is-touch-device\",\"\"],null],\"\\n  \",[27,\"if\",[[23,[\"isExported\"]],\"disable-pointer-events\"],null]]]],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,0,[\"workspace\",\"isGlobalSpinnerVisible\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"global-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"workspace\",\"isScheduleGenerate\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"schedule-generate-modal\",null,[[\"stackFrame\"],[[23,[\"workspace\",\"stack\",\"lastObject\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header-nav-wrapper\"],[9],[0,\"\\n    \"],[1,[27,\"menu-navbar\",null,[[\"isMenuVisible\"],[[22,0,[\"isAuthenticated\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"workspace\",\"isFleetManagementOpen\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"iq-widgets/fleet-manager-widget\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"workspace\",\"isScheduleMetricsOpen\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"schedule-metrics\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"workspace\",\"isOptimizeScheduleOpen\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"optimize-schedule\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"isAuthenticated\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"notification-bar\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content-wrapper\"],[9],[0,\"\\n    \"],[1,[21,\"action-modal\"],false],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});