define("adept-iq/classes/markers/stops/schedule-stop", ["exports", "adept-iq/classes/markers/stops/base", "adept-iq/models/break-type", "adept-iq/config/environment"], function (_exports, _base, _breakType, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // see `app/pods/components/iq-widgets/map-widget/config/marker.js`
  const NEXT_STYLE_SUFFIX = 'Next';
  const WHEELCHAIR_STYLE_SUFFIX = 'Wheelchair';

  var _default = _base.default.extend({
    record: null,
    // this is a trip-stop or route-break
    rowIndex: 1,
    // `BaseMarker` overrides
    isActive: true,
    lat: Ember.computed.readOnly('record.lat'),
    lng: Ember.computed.readOnly('record.lng'),
    // `BaseStopMarker` overrides
    travelNeeds: Ember.computed.readOnly('record.trip.segment.leg.legTravelNeeds'),
    stopType: Ember.computed.readOnly('record.stopType'),
    isWheelchair: Ember.computed.readOnly('hasWheelchairTravelNeed'),
    stopId: Ember.computed.readOnly('record.id'),
    // @TODO Likely Stop Point Records already computes these properties better to use readOnly
    // Additionally hard coded strings should be named within model similar to PERFORM_STOP_STATUS
    isPick: Ember.computed.equal('stopType', 'pick'),
    isDrop: Ember.computed.equal('stopType', 'drop'),
    isDeploy: Ember.computed.equal('stopType', 'deployStop'),
    isBreak: Ember.computed.equal('stopType', 'break'),
    isFloatingBreak: Ember.computed.equal('record.breakType.name', _breakType.FLOATING_BREAK),
    isGaragePullIn: Ember.computed.equal('stopType', 'garagePullin'),
    isGaragePullOut: Ember.computed.equal('stopType', 'garagePullout'),
    isStopHidden: Ember.computed.and('isNoShow', 'isDrop'),
    label: Ember.computed.oneWay('iqStopLabel'),
    isGarage: Ember.computed.or('isGaragePullIn', 'isGaragePullOut'),
    isHighLighted: Ember.computed.or('record.{isHighLighted,trip.isHighLighted,trip.rider.isHighLighted}'),
    isNext: Ember.computed('stopId', 'currentStopId', function () {
      const stopId = this.get('stopId');
      return Ember.isPresent(stopId) && stopId === this.get('currentStopId');
    }),
    // local properties
    currentStopId: Ember.computed('stopPoint.dispatchRoute.currentStopOrdinalId', function () {
      return this.get('stopPoint.dispatchRoute.currentStopOrdinalId');
    }),
    hasWheelchairTravelNeed: Ember.computed('travelNeeds.@each.isWheelchair', function () {
      // this check might not be necessary anymore
      if (_environment.default.APP.avlmLite) return false;
      const travelNeeds = this.get('travelNeeds');
      if (Ember.isEmpty(travelNeeds)) return false;
      return travelNeeds.any(travelNeed => travelNeed.get('isWheelchair'));
    }),
    iqStopLabel: Ember.computed('stopType', 'id', function () {
      const stopType = this.get('stopType');
      const stopId = this.get('id');
      return `${Ember.String.capitalize(stopType)} stop ${stopId}`;
    }),
    stopLabel: Ember.computed('stopType', function () {
      const stopType = this.get('stopType');
      return `${Ember.String.capitalize(stopType)} stop`;
    }),
    stopStyle: Ember.computed('typeStylePrefix', 'rowIndex', function () {
      const typeStylePrefix = this.get('typeStylePrefix');
      const rowIndex = this.get('rowIndex');
      const style = `${typeStylePrefix}${rowIndex}`;
      return style;
    }),
    typeStylePrefix: Ember.computed('stopType', function () {
      switch (this.get('stopType')) {
        case 'pick':
          return 'pick';

        case 'drop':
          return 'drop';

        case 'pullinGarage':
        case 'pulloutGarage':
          return 'garage';
        // both types use same icon

        default:
          // we don't care about break, noshow, etc.
          return null;
      }
    }),
    nextStyleSuffix: Ember.computed('isNext', function () {
      return this.get('isNext') ? NEXT_STYLE_SUFFIX : null;
    }),
    wheelchairStyleSuffix: Ember.computed('isWheelchair', 'isGarage', function () {
      // garage icons do not have a wheelchair version
      if (this.get('isGarage')) return null;
      return this.get('isWheelchair') ? WHEELCHAIR_STYLE_SUFFIX : null;
    })
  });

  _exports.default = _default;
});