define("adept-iq/pods/components/iq-widgets/map-widget/config/marker-highlight", ["exports", "adept-iq/config/icon-paths"], function (_exports, _iconPaths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const stopIconSize = [23.0, 23.0];
  const shadowVehicleAnchor = [15, 15];
  const shadowStopAnchor = [16.5, 12.5];
  const shadowAnchor = [14, 15];
  const stopIconAnchor = stopIconSize.map(x => x / 2.0);
  var _default = {
    location: {
      icon: window.L.icon({
        icon: {
          icon: 'fa fa-building',
          iconSize: [32, 37],
          iconAnchor: [16, 2],
          style: {
            color: '#fff'
          },
          noPlainSVG: true
        }
      })
    },
    pick1: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlanned1,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pick2: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlanned2,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pick3: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlanned3,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pick4: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlanned4,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pick5: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlanned5,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    deployStop: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.planned,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlannedWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickNext: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickNext,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickNextWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickNextWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickDanger: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlannedInDanger,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickDangerWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlannedInDangerWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickDangerNext: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickNextInDanger,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickDangerNextWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickNextInDangerWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickLate: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlannedLate,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickLateWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickPlannedLateWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickLateNextWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickNextLateWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickLateNext: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickNextLate,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowAnchor
      })
    },
    pickPerformed: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickCompleted,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    pickPerformedWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.pickCompletedWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    drop1: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlanned1,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    drop2: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlanned2,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    drop3: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlanned3,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    drop4: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlanned4,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    drop5: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlanned5,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlannedWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropNext: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropNext,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowAnchor
      })
    },
    dropNextWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropNextWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropDanger: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlannedInDanger,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropDangerWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlannedInDangerWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropDangerNext: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropNextInDanger,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropDangerNextWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropNextInDangerWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropLate: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlannedLate,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropLateWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropPlannedLateWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropLateNextWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropNextLateWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropLateNext: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropNextLate,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropPerformed: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropCompleted,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    dropPerformedWheelchair: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.dropCompletedWheelchair,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garage1: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePlanned1,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garage2: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePlanned2,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garage3: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePlanned3,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garage4: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePlanned4,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garage5: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePlanned5,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garageNext: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garageNext,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garageDanger: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePlannedInDanger,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garageDangerNext: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garageNextInDanger,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garageLate: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garagePlannedLate,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garageLateNext: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garageNextLate,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    garagePerformed: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.garageCompleted,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowStopAnchor
      })
    },
    break: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.break,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowAnchor
      })
    },
    floatingBreak: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.break,
        iconSize: stopIconSize,
        iconAnchor: [30, 6],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: [33, 9]
      })
    },
    noshow: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.stops.noshow,
        iconSize: stopIconSize,
        iconAnchor: stopIconAnchor,
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicle: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicle,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleAVLM: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicleAVLM,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleRoadSupervisor: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.roadSupervisor,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleDrivingRoadSupervisor: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.roadSupervisor,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleLoggedRoadSupervisor: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.loggedRoadSupervisor,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleDanger: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicleDanger,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleLate: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicleLate,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleEmergency: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkEmergency,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleEmergencyDanger: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkEmergencyDanger,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleEmergencyLate: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkEmergencyLate,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleEmergencyAVLM: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkEmergency,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleDriving: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWork,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleDrivingAVLM: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkAVLM,
        iconSize: [32, 32],
        iconAnchor: [16, 16],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleDrivingLate: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkLate,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleDrivingDanger: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkDanger,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleDrivingEmergency: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkEmergency,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleDrivingEmergencyAVLM: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkEmergency,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleDrivingEmergencyDanger: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkEmergencyDanger,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleDrivingEmergencyLate: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWorkEmergencyLate,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    pinPoint: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.maps.pinPoint,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleBreakdown: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicleBreakdown,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleOnBreak: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.onBreak,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleCommunicationFailed: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicleCommunicationFailed,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleGPSFailed: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicleGPSFailed,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    },
    vehicleNotSigned: {
      icon: window.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicleNotSigned,
        iconSize: [23, 23],
        iconAnchor: [11.5, 11.5],
        shadowUrl: _iconPaths.default.maps.highlight,
        shadowSize: [30, 30],
        shadowAnchor: shadowVehicleAnchor
      })
    }
  };
  _exports.default = _default;
});